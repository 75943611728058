<template>
  <div class="contact">
    <!-- 关于我们 -->
    <div class="WebMain">
      <div class="logoContent">
        <!-- <p class="p32">关于我们</p> -->
        <!-- <img src="../../assets/img/banner/icon.png" alt="" /> -->
      </div>
      <div class="textContent">
        <div class="left">
          <div class="Title">
            <p>
              <span>关于&nbsp;</span>
              <span class="color">万海</span>
            </p>
            <span>About Wanhai</span>
          </div>
          <div class="text">
            <p class="p20">
              万海投资是一家致力于为世界各地客户提供金融投资咨询及相关服务的公司，提供包括外汇、贵金属、原油、商品、股指等产品的投资咨询服务，是英国外汇黄金交易所LMAX（伦敦多项金融资产交易所）的长期战略合作伙伴，同时拥有专业的投资分析、行情咨询、行业培训，LMAX代理，LMAX开户，LMAX机构清算账户及相关服务。
            </p>
            <p class="p20">
              我们以成为具有国际竞争力的金融投资企业为愿景，以为客户实现价值最大化为使命，坚持为客户提供最好的服务，不断凝聚相关领域的精英，力求为广大的客户提供专业化的、精湛的技术分析和优质的跟踪服务。
            </p>
            <!-- <p class="styleText">ABOUT US</p> -->
          </div>
        </div>
        <div class="right">
          <div class="Title">
            <p>
              <span class="color">联系&nbsp;</span>
              <span>我们</span>
            </p>
            <span>Contact Us</span>
          </div>
          <div class="text">
            <div class="top">
              <div class="item">
                <p class="p18">— 万海投资香港地址 —</p>
                <p class="p14">
                  Unit 5, 27/F RICHMOND COMMBLDG 109 ARGYLE ST MONGKOK KLN
                </p>
              </div>
              <div class="item">
                <p class="p18">— LMAX英国地址 —</p>
                <p class="p14">
                  Yellow Building, 1A Nicholas Road,London, W11 4AN
                </p>
              </div>
              <div class="item">
                <p class="p18">— LMAX英国总部联系方式 —</p>
                <p class="p14">英国客服：+ 44 20 3192 2555</p>
              </div>
            </div>
            <div class="center"></div>
            <div class="bottom">
              <div class="item">
                <img src="../../assets/img/contact/WechatIMG7@2x.png" alt="" />
                <span class="p18"
                  >客&nbsp;&nbsp;户&nbsp;&nbsp;服&nbsp;&nbsp;务：
                  cs@maxiveinvest.com</span
                >
              </div>
              <div class="item">
                <img src="../../assets/img/contact/WechatIMG8@2x.png" alt="" />
                <span class="p18">IB与机构合作： ib@maxiveinvest.com</span>
              </div>
              <div class="item">
                <img src="../../assets/img/contact/WechatIMG6@2x.png" alt="" />
                <span class="p18"
                  >客&nbsp;&nbsp;服&nbsp;&nbsp;微&nbsp;&nbsp;信：
                </span>
                <img
                  class="code"
                  src="../../assets/img/contact/二维码@2x.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { articleDetailAPI, articleListAPI } from "@/api/article/article";
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.contact {
  background: url("../../assets/img/contact/背景-3@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  min-height: 750px;
  .WebMain {
    padding-top: 40px;
    padding-bottom: 100px;
    .logoContent {
      text-align: right;
      p {
        border-right: 7px solid var(--custom-color);
        padding-right: 13px;
        margin-bottom: 14px;
      }
      img {
        height: 60px;
      }
    }
    .textContent {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      .Title {
        margin-bottom: 23px;
        p {
          margin-bottom: 10px;
          span {
            font-size: 26px;
          }
          .color {
            color: var(--custom-color);
          }
        }
        span {
          font-size: 12px;
        }
      }
      .text {
        background: rgba(53,71,102,0.75);
        border-radius: 16px;
        border: 1px solid #82BFE5;
        backdrop-filter: blur(1px);
        box-sizing: border-box;
      }
      .left {
        box-sizing: border-box;
        .text {
          width: 700px;
          height: 476px;
          position: relative;
          overflow: hidden;
          padding: 26px 35px;

          p {
            color: #FFFFFF;
            line-height: 42px;
            text-shadow: 0px 1px 1px rgba(0,0,0,0.5);
            margin-bottom: 10px;
          }
          .styleText {
            position: absolute;
            bottom: -31px;
            font-family: Arial-Black, Arial;
            font-size: 56px;
            font-weight: 900;
            color: rgba(255, 255, 255, 0.1);
            line-height: 79px;
            letter-spacing: 4px;
            text-shadow: 0px 2px 4px rgba(210, 210, 210, 0.5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .right {
        box-sizing: border-box;
        margin-left: 80px;
        .text {
          width: 470px;
          height: 476px;
          padding: 26px 30px;
          .top {
            .item {
              text-align: center;
              margin-bottom: 20px;
              p {
                line-height: 27px;
                // margin-bottom: 3px;
              }
            }
          }
          .center {
            height: 1px;
            width: 360px;
            margin: 0 auto;
            background: #ffffff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.64);
            opacity: 0.27;
          }
          .bottom {
            padding: 0 20px;
            .item {
              margin: 0 auto;
              margin-top: 23px;
              display: flex;
              align-items: center;
              img {
                height: 26px;
                margin-right: 15px;
              }
              .code {
                height: 52px;
                width: 52px;
                margin-left: 134px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .contact {
    padding-bottom: 50px;
    .WebMain .textContent {
    .left,
    .right {
      .text {
        width: 100%;
        height: auto;
      }
    }
    .right {
      margin-left: 0;
      margin-top: 30px;
      .text {
        .center {
          width: 100%;
        }
        .bottom .item .code {
          margin-left: 30px;
        }
      }
    }
  }
  }
}
</style>